<div class="container mx-auto w-full">
  <div class="w-full flex items-center justify-between">
    <h1 class="text-2xl text-primary font-bold">
      <span *ngIf="isProducer" transloco="Producers">Producers</span>
      <span *ngIf="!isProducer" transloco="Clients">Clients</span>
    </h1>
    <button
      type="button"
      (click)="addNewClient()"
      class="tw-btn-secondary-outline"
    >
      <span *ngIf="isProducer" transloco="+ New Producer"
        >+ New Producer</span
      >
      <span *ngIf="!isProducer" transloco="+ New Client"
        >+ New Client</span
      >
    </button>
  </div>
  <div class="mt-3 mb-3" *transloco="let t">
    <div class="w-full flex mt-6 flex-wrap gap-4 items-center">
      <div class="relative min-w-[380px] flex-grow">
        <input
          type="search"
          [formControl]="searchControl"
          id="default-search"
          class="block w-full p-3 2xl:p-4 pe-14 text-xs 2xl:text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-darkBlue1 focus:border-darkBlue1"
          [placeholder]="t('Company-Name, Name or E-Mail of Contact person')"
        />
        <button
          (click)="doSearch()"
          class="absolute right-0.5 top-1/2 -translate-y-1/2 rounded-lg px-4 py-2"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.9992 21L16.6992 16.7"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div
    class="relative overflow-x-auto shadow-md sm:rounded-lg"
    *transloco="let t"
  >
    <table
      class="w-full mt-4 text-xs 2xl:text-sm text-left rtl:text-right text-gray-500"
    >
      <thead class="text-xs 2xl:text-sm text-black uppercase">
        <tr>
          <th scope="col" class="border-t border-b border-gray3 px-6 py-3">
            <app-sortable-table-header
              [displayName]="t('Company Name')"
              fieldName="company_name"
            ></app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-6 py-3 rounded-l w-1/5"
            *isSuperAdmin
          >
            <app-sortable-table-header
              [displayName]="t('Customer Name')"
              fieldName="customer_id"
            ></app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-6 py-3"
            transloco="Name"
          >
            Name
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-6 py-3"
            transloco="Email"
          >
            Email
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-6 py-3 rounded-l w-1/5"
          >
            <app-sortable-table-header
              class="flex w-full justify-center"
              [displayName]="t('Open Requests')"
              fieldName="open_requests_count"
            ></app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-r rounded-r border-gray3 px-2 py-3 w-1/5 text-center"
            transloco="Actions"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let client of clients">
          <tr
            class="bg-white text-xs 2xl:text-sm border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            data-expanded="false"
          >
            <td
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-semibold text-base text-primary whitespace-nowrap truncate cursor-pointer"
              (click)="updateExpandedClientId(client.id)"
              [ngClass]="{
                'bg-gray8 my-4 rounded-tl-2xl': expandedClientId === client.id
              }"
            >
              <div class="flex gap-6 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="text-primary"
                  viewBox="0 0 256 256"
                  class="duration-500 transition-all transform"
                  [ngClass]="{ 'rotate-180': expandedClientId === client.id }"
                >
                  <path
                    d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"
                  ></path>
                </svg>
                {{ client.company_name }}
              </div>
            </td>
            <td
              *isSuperAdmin
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-medium text-gray4 whitespace-nowrap truncate cursor-pointer"
              (click)="updateExpandedClientId(client.id)"
              [ngClass]="{ 'bg-gray8': expandedClientId === client.id }"
            >
              <app-company-name-display
                [customerId]="client.customer_id"
              ></app-company-name-display>
            </td>
            <td [ngClass]="{ 'bg-gray8': expandedClientId === client.id }"></td>
            <td [ngClass]="{ 'bg-gray8': expandedClientId === client.id }"></td>
            <td
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-medium text-gray4 whitespace-nowrap truncate cursor-pointer text-center"
              (click)="updateExpandedClientId(client.id)"
              [ngClass]="{ 'bg-gray8': expandedClientId === client.id }"
            >
              {{ client.open_requests || "-" }}
            </td>
            <td
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-medium text-gray-900 whitespace-nowrap"
              [ngClass]="{
                'bg-gray8 rounded-tr-2xl': expandedClientId === client.id
              }"
            >
              <div class="w-full flex justify-end">
                <div
                  class="flex border border-gray3 rounded-lg px-4 py-2 w-fit bg-white"
                >
                  <button
                    class="font-semibold text-primary text-xs 2xl:text-sm transition-all duration-200 hover:bg-gray-100"
                    (click)="editClient(client.id)"
                    transloco="Edit"
                  >
                    Edit
                  </button>
                  <div class="text-gray3 mx-2">|</div>
                  <a
                    [routerLink]="[client.id.toString()]"
                    class="font-semibold text-primary text-xs 2xl:text-sm transition-all duration-200 hover:bg-gray-100"
                    transloco="CLIENT_VIEW"
                  >
                    View
                  </a>
                  <ng-container *ngIf="!isProducer">
                    <div class="text-gray3 mx-2">|</div>
                    <button
                      class="font-semibold text-primary text-xs 2xl:text-sm transition-all duration-200 hover:bg-gray-100"
                      (click)="createNewRequestForClient(client.id)"
                      transloco="New Request"
                    >
                      New Request
                    </button>
                  </ng-container>
                </div>
              </div>
            </td>
          </tr>
          <tr
            class="bg-gray8"
            *ngFor="let contact of client.contacts; let isLast = last"
          >
            <td
              [ngClass]="{
                'hidden opacity-0': expandedClientId !== client.id,
                'rounded-bl-2xl': isLast
              }"
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-semibold text-sm text-gray4 whitespace-nowrap truncate"
            >
              <div class="flex gap-6 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="text-primary"
                  viewBox="0 0 256 256"
                  class="invisible"
                  [ngClass]="{ 'rotate-180': expandedClientId === client.id }"
                >
                  <path
                    d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"
                  ></path>
                </svg>
                {{ client.company_name }}
              </div>
            </td>
            <td
              [ngClass]="{ 'hidden opacity-0': expandedClientId !== client.id }"
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-semibold text-sm text-gray4"
            >
              {{ contact | transformName }}
            </td>
            <td
              [ngClass]="{ 'hidden opacity-0': expandedClientId !== client.id }"
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-semibold text-sm text-gray4"
            >
              {{ contact.email }}
            </td>
            <td
              [ngClass]="{ 'hidden opacity-0': expandedClientId !== client.id }"
            ></td>
            <td
              [ngClass]="{
                'hidden opacity-0': expandedClientId !== client.id,
                'rounded-br-2xl': isLast
              }"
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-medium text-gray-900 whitespace-nowrap"
            >
              <div class="flex w-full justify-end">
                <div
                  class="flex justify-end border border-gray3 rounded-lg px-4 py-2 w-fit bg-white"
                >
                  <button
                    class="font-semibold text-primary text-xs 2xl:text-sm transition-all duration-200 hover:bg-gray-100"
                    (click)="editContact(contact.id)"
                    transloco="Edit"
                  >
                    Edit
                  </button>
                  <div class="text-gray3 mx-2">|</div>
                  <button
                    class="font-semibold text-primary text-xs 2xl:text-sm transition-all duration-200 hover:bg-gray-100"
                    (click)="initDeleteContact(contact.id, client.id)"
                    transloco="Delete"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <app-pagination [total]="totalClientsCount"></app-pagination>
</div>

<button
  data-modal-target="confirmDeleteModal-clv"
  data-modal-toggle="confirmDeleteModal-clv"
  class="hidden"
  #toggleConfirmDeleteModalBtn
></button>

<div
  id="confirmDeleteModal-clv"
  #confirmDeleteModal
  data-delete-type="contact"
  tabindex="-1"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
>
  <div class="relative p-3 2xl:p-4 w-full max-w-md max-h-full">
    <div class="relative bg-white rounded-lg shadow-xl dark:bg-gray-700">
      <button
        type="button"
        class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        data-modal-hide="confirmDeleteModal-clv"
      >
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span class="sr-only" transloco="Close modal">Close modal</span>
      </button>
      <div class="p-4 md:p-5 text-center">
        <svg
          class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
        <h3
          class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
          transloco="Are you sure you want to remove this contact?"
        >
          Are you sure you want to remove this contact?
        </h3>
        <button
          data-modal-hide="confirmDeleteModal-clv"
          type="button"
          (click)="openContactSuccessorSelector()"
          class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-xs 2xl:text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
          transloco="Yes"
        >
          Yes
        </button>
        <button
          data-modal-hide="confirmDeleteModal-clv"
          type="button"
          class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          transloco="No"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>

<app-custom-modal
  [modalEvents]="clientSuccessorPickerModalService.modalEvents"
  classes="max-w-3xl"
>
  <h1
    header
    class="text-xl font-semibold"
    transloco="Select a new person to assign active requests"
  >
    Select a new person to assign active requests
  </h1>
  <div content-body>
    <div
      *ngIf="
        clientSuccessorPickerModalService.availableContacts &&
        clientSuccessorPickerModalService.availableContacts.length > 0
      "
    >
      <select
        id="customer"
        class="border text-xs 2xl:text-sm rounded-lg focus:ring-primaryBtn focus:border-primaryBtn block w-full p-2.5"
        [ngClass]="
          editClientModalService.customerIdControl.touched &&
          editClientModalService.customerIdControl.errors
            ? 'bg-red-50 border-red-500 text-red-900'
            : 'bg-gray-50 border-gray-300  text-gray-900'
        "
        [formControl]="
          clientSuccessorPickerModalService.selectedSuccessorContactId
        "
      >
        <option
          [value]="contact.id"
          *ngFor="
            let contact of clientSuccessorPickerModalService.availableContacts
          "
        >
          {{ contact.email }}
        </option>
      </select>
      <div class="flex justify-between items-center mt-10 pb-6">
        <button
          type="button"
          class="border-2 font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center w-36 disabled:opacity-60"
          transloco="Cancel"
          [disabled]="clientSuccessorPickerModalService.isSubmitting"
          (click)="clientSuccessorPickerModalService.closeModal()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="border-2 font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center w-36 disabled:opacity-60"
          transloco="Submit"
          [disabled]="
            clientSuccessorPickerModalService.isSubmitting ||
            !clientSuccessorPickerModalService.selectedSuccessorContactId.value
          "
          (click)="clientSuccessorPickerModalService.handleSubmit()"
        >
          Submit
        </button>
      </div>
    </div>
    <div
      *ngIf="
        clientSuccessorPickerModalService.availableContacts &&
        clientSuccessorPickerModalService.availableContacts.length === 0
      "
    >
      <div class="px-3 py-3 bg-red-500 text-white rounded-xl">
        At least 1 contact is required
      </div>
      <div class="flex justify-end items-center mt-10 pb-6">
        <button
          type="button"
          class="border-2 font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center w-36"
          transloco="Close"
          (click)="clientSuccessorPickerModalService.closeModal()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</app-custom-modal>
