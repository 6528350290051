<div
  [formGroup]="editRequestModalService.requestFormGroup"
  class="text-primary px-6 2xl:px-0"
>
  <section
    class="flex justify-between flex-wrap gap-4 border-b border-gray5 sticky top-0 bg-white pt-3 pb-4"
  >
    <div class="flex flex-wrap gap-6">
      <button
        class="h-[60px] 2xl:h-[72px] w-[60px] 2xl:w-[72px] flex justify-center items-center rounded-lg border border-gray3"
        (click)="goBack()"
        [disabled]="editRequestModalService.requestFormGroup.disabled"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.8214 21.1789C13.509 20.8664 13.3334 20.4426 13.3334 20.0006C13.3334 19.5587 13.509 19.1348 13.8214 18.8223L23.2498 9.39395C23.4035 9.23476 23.5874 9.10779 23.7907 9.02044C23.9941 8.93309 24.2128 8.88712 24.4341 8.88519C24.6554 8.88327 24.8748 8.92544 25.0797 9.00924C25.2845 9.09304 25.4706 9.2168 25.6271 9.37329C25.7836 9.52978 25.9073 9.71586 25.9911 9.92069C26.0749 10.1255 26.1171 10.345 26.1152 10.5663C26.1132 10.7876 26.0673 11.0063 25.9799 11.2096C25.8926 11.413 25.7656 11.5969 25.6064 11.7506L17.3564 20.0006L25.6064 28.2506C25.91 28.565 26.078 28.986 26.0742 29.4229C26.0704 29.8599 25.8951 30.278 25.5861 30.587C25.2771 30.896 24.8591 31.0713 24.4221 31.0751C23.9851 31.0789 23.5641 30.9109 23.2498 30.6073L13.8214 21.1789Z"
            fill="black"
          />
        </svg>
      </button>
      <div class="flex flex-col gap-2">
        <h1 class="font-bold text-2xl">
          <ng-container *ngIf="isEditPage" transloco="Edit Request"
            >Edit Request</ng-container
          >
          <ng-container *ngIf="!isEditPage" transloco="Create New Request"
            >Create New Request</ng-container
          >
        </h1>
        <ul class="flex items-center gap-2 text-xs 2xl:text-sm">
          <li class="font-normal text-xs text-gray4">
            <a routerLink="/requests" transloco="Request File ">Request </a>
            <span> / </span>
          </li>
          <li
            class="font-normal text-xs text-gray4"
            *ngIf="fromRequestFolderId"
          >
            <a routerLink="/requests/folders/{{ fromRequestFolderId }}"
              >{{ fromRequestFolderData?.title }} /</a
            >
          </li>
          <li class="font-semibold text-13px text-primary">
            {{ isEditPage ? "Edit Request" : "Create New Request" }}
          </li>
        </ul>
      </div>
    </div>
    <div class="flex flex-wrap gap-4 items-center">
      <button
        class="tw-btn-ghost-outline min-w-[100px]"
        type="button"
        [disabled]="editRequestModalService.requestFormGroup.disabled"
        (click)="goBack()"
        transloco="Cancel"
      >
        Cancel
      </button>
      <button
        class="tw-btn-secondary min-w-[100px]"
        type="button"
        [disabled]="editRequestModalService.requestFormGroup.disabled"
        (click)="editRequestModalService.handleSubmit()"
        transloco="Submit"
      >
        Submit
      </button>
    </div>
  </section>
  <section class="flex-col pt-4" *transloco="let t">
    <h2 class="mt-10 mb-6 font-bold text-22px" transloco="General Info">
      General Info
    </h2>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
      <div class="flex flex-col gap-2">
        <label class="font-semibold text-sm" transloco="Folder">Folder</label>
        <app-custom-dropdown
          [buttonText]="
            this.editRequestModalService.requestFolderControl.value
              ? requestFolderService.localFolderNameMap.get(
                  this.editRequestModalService.requestFolderControl.value
                )
              : t('Select Folder')
          "
        >
          <ng-template #recursiveList let-list>
            <li *ngFor="let requestFolder of list" class="border-l">
              <button
                class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary font-medium duration-200 w-full"
                (click)="changeRequestFolderValue(requestFolder.id)"
              >
                {{ requestFolder.title }}
              </button>
              <ul *ngIf="requestFolder.children?.length" class="ml-3">
                <ng-container
                  *ngTemplateOutlet="
                    recursiveList;
                    context: { $implicit: requestFolder.children }
                  "
                ></ng-container>
              </ul>
            </li>
          </ng-template>
          <ng-container
            *ngTemplateOutlet="
              recursiveList;
              context: { $implicit: requestFolderList }
            "
          ></ng-container>
        </app-custom-dropdown>
      </div>
      <div class="hidden lg:block"></div>
      <div class="flex flex-col gap-2">
        <label
          class="font-semibold text-sm"
          for="request_title"
          transloco="Request Title"
          >Request Title</label
        >
        <input
          type="text"
          formControlName="title"
          id="request_title"
          [placeholder]="t('Input request title...')"
          class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-full font-semibold text-sm"
          [class.border-red-400]="
            editRequestModalService.requestFormGroup.controls.title.invalid &&
            editRequestModalService.requestFormGroup.controls.title.touched
          "
        />
        <div
          class="text-red-400"
          *ngIf="
            editRequestModalService.requestFormGroup.controls.title.errors &&
            editRequestModalService.requestFormGroup.controls.title.touched
          "
        >
          <small
            *ngIf="
              editRequestModalService.requestFormGroup.controls.title.errors
                .required
            "
            transloco="required"
            >required</small
          >
          <small
            *ngIf="
              editRequestModalService.requestFormGroup.controls.title.errors
                .maxlength
            "
            transloco="Must be less than 255 characters"
          >
            Must be less than 255 characters
          </small>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <div class="flex gap-3">
          <label
            class="font-semibold text-sm flex"
            for="project_number"
            transloco="Project Number"
            >Project Number
          </label>
          <span
            class="text-gray4 font-normal italic text-sm flex items-center gap-2"
            ><svg
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="3" cy="3" r="2.5" fill="#C4D2DB" stroke="#C4D2DB" />
            </svg>
            {{ t("optional") }}</span
          >
        </div>
        <input
          type="text"
          formControlName="project_number"
          id="project_number"
          [placeholder]="t('Input project number...')"
          class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-full font-semibold text-sm"
          [class.border-red-400]="
            editRequestModalService.requestFormGroup.controls.project_number
              .invalid &&
            editRequestModalService.requestFormGroup.controls.project_number
              .touched
          "
        />
        <div
          class="text-red-400"
          *ngIf="
            editRequestModalService.requestFormGroup.controls.project_number
              .errors &&
            editRequestModalService.requestFormGroup.controls.project_number
              .touched
          "
        >
          <small
            *ngIf="
              editRequestModalService.requestFormGroup.controls.project_number
                .errors.maxlength
            "
            transloco="Must be less than 255 characters"
          >
            Must be less than 255 characters
          </small>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <label class="font-semibold text-sm" for="client_id" transloco="Client"
          >Client</label
        >
        <app-custom-dropdown
          id="client_id"
          [buttonText]="
            this.editRequestModalService.clientIdControl.value
              ? clientService.localClientNameMap.get(
                  this.editRequestModalService.clientIdControl.value
                )
              : selectClientText
          "
          [hasError]="
            editRequestModalService.requestFormGroup.controls.client_id
              .invalid &&
            editRequestModalService.requestFormGroup.controls.client_id.touched
          "
          *ngIf="!editRequestModalService.requestFormGroup.value.id"
        >
          <li header>
            <div class="relative min-w-[380px] flex-grow my-3 mx-2">
              <input
                type="search"
                [formControl]="clientFilterText"
                class="block w-full p-3 2xl:p-4 text-xs 2xl:text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-darkBlue1 focus:border-darkBlue1"
                [placeholder]="t('Search clients...')"
              />
            </div>
          </li>
          <li *ngFor="let client of filteredAvailableClients" class="">
            <button
              class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary font-medium duration-200 w-full"
              (click)="
                editRequestModalService.clientIdControl.setValue(client.id)
              "
            >
              {{ client.company_name }}
            </button>
          </li>
        </app-custom-dropdown>
        <div
          class="text-red-400"
          *ngIf="
            editRequestModalService.requestFormGroup.controls.client_id
              .errors &&
            editRequestModalService.requestFormGroup.controls.client_id.touched
          "
        >
          <small
            *ngIf="
              editRequestModalService.requestFormGroup.controls.client_id.errors
                .required
            "
            transloco="required"
            >required</small
          >
        </div>
        <div
          *ngIf="editRequestModalService.requestFormGroup.value.id"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-xs 2xl:text-sm rounded-lg focus:ring-primaryBtn focus:border-primaryBtn block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primaryBtn dark:focus:border-primaryBtn"
        >
          {{
            clientService.getLocalClientById(
              editRequestModalService.requestFormGroup.value.client_id
            )?.company_name
          }}
        </div>
      </div>
      <div class="flex flex-col gap-2 row-span-2">
        <label
          class="font-semibold text-sm"
          for="description"
          transloco="Description"
          >Description</label
        >
        <textarea
          id="description"
          rows="6"
          class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-full font-semibold text-sm"
          [placeholder]="t('Enter your description...')"
          formControlName="description"
          [class.border-red-400]="
            editRequestModalService.requestFormGroup.controls.description
              .invalid &&
            editRequestModalService.requestFormGroup.controls.description
              .touched
          "
        ></textarea>
        <div
          class="text-red-400"
          *ngIf="
            editRequestModalService.requestFormGroup.controls.description
              .errors &&
            editRequestModalService.requestFormGroup.controls.description
              .touched
          "
        >
          <small
            *ngIf="
              editRequestModalService.requestFormGroup.controls.description
                .errors.required
            "
            transloco="required"
            >required</small
          >
          <small
            *ngIf="
              editRequestModalService.requestFormGroup.controls.description
                .errors.maxlength
            "
            transloco="Must be less than 1000 characters"
          >
            Must be less than 1000 characters
          </small>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <label
          class="font-semibold text-sm"
          for="contact_id"
          transloco="Contact Person"
          >Contact Person</label
        >
        <select
          id="contact_id"
          class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-full font-semibold text-sm"
          formControlName="contact_id"
          [class.border-red-400]="
            editRequestModalService.requestFormGroup.controls.contact_id
              .invalid &&
            editRequestModalService.requestFormGroup.controls.contact_id.touched
          "
        >
          <option value="" disabled transloco="Select Contact">
            Select Contact
          </option>
          <option
            [value]="contact.id"
            *ngFor="
              let contact of clientService.getLocalClientById(
                editRequestModalService.requestFormGroup.controls.client_id
                  .value
              )?.contacts || []
            "
          >
            {{ contact.first_name }} {{ contact.last_name }} -
            {{ contact.email }}
          </option>
        </select>
        <div
          class="text-red-400"
          *ngIf="
            editRequestModalService.requestFormGroup.controls.contact_id
              .errors &&
            editRequestModalService.requestFormGroup.controls.contact_id.touched
          "
        >
          <small
            *ngIf="
              editRequestModalService.requestFormGroup.controls.contact_id
                .errors.required
            "
            transloco="required"
            >required</small
          >
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <label
          class="font-semibold text-sm flex"
          for="due_date"
          transloco="Due Date"
          >Due Date
          <span
            class="text-gray4 ml-2 font-normal italic text-xs 2xl:text-sm flex items-center gap-2"
            ><svg
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="3" cy="3" r="2.5" fill="#C4D2DB" stroke="#C4D2DB" />
            </svg>
            optional</span
          ></label
        >
        <input
          type="date"
          formControlName="due_date"
          id="due_date"
          class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-fit"
          [class.border-red-400]="
            editRequestModalService.requestFormGroup.controls.due_date
              .invalid &&
            editRequestModalService.requestFormGroup.controls.due_date.touched
          "
        />
        <div
          class="text-red-400"
          *ngIf="
            editRequestModalService.requestFormGroup.controls.due_date.errors &&
            editRequestModalService.requestFormGroup.controls.due_date.touched
          "
        ></div>
      </div>
    </div>
  </section>
  <section class="flex-col" *transloco="let t">
    <h2 class="mt-10 mb-6 font-bold text-22px" transloco="Requirements">
      Requirements
    </h2>
    <div class="grid grid-cols-1 lg:grid-cols-12 gap-4">
      <div class="lg:col-span-4 flex flex-col gap-6">
        <div
          *ngFor="
            let sectionCollection of editRequestModalService
              .sectionCollectionsFormArr.controls;
            let cIndex = index;
            let cIsLast = last
          "
          formArrayName="section_collections"
          class="rounded-xl border p-4 cursor-pointer"
          [ngClass]="{
            'border-orange1 shadow-sm':
              editRequestModalService.selectedCollectionIndex === cIndex
          }"
          (click)="editRequestModalService.selectedCollectionIndex = cIndex"
        >
          <div class="text-xs font-normal text-light-blue3">
            {{ t("Collection") }} {{ cIndex + 1 }}
          </div>
          <div
            class="flex w-full justify-between mt-2 pb-1"
            [ngClass]="{
              'border-b mb-2 pb-3':
                editRequestModalService.selectedCollectionIndex === cIndex
            }"
          >
            <div class="font-semibold text-darkBlue3 text-lg">
              {{ sectionCollection.get("title")?.value }}
            </div>
            <div class="flex items-end gap-2">
              <button
                (click)="
                  sectionCollectionEditModalService.openModal(
                    cIndex,
                    $any(sectionCollection)
                  )
                "
              >
                <img src="assets/imgs/icons/edit-icon.svg" alt="edit" />
              </button>
              <button
                [disabled]="editRequestModalService.requestFormGroup.disabled"
                (click)="editRequestModalService.removeSectionCollection(cIndex)"
              >
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  class=""
                  xmlns="http://www.w3.org/2000/svg"
                  [ngClass]="{
                    'fill-red1 stroke-red1':
                      editRequestModalService.selectedCollectionIndex ===
                      cIndex,
                    'fill-light-blue3 stroke-light-blue3':
                      editRequestModalService.selectedCollectionIndex !== cIndex
                  }"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18 4H0V7C0.530433 7 1.03914 7.21071 1.41421 7.58579C1.78929 7.96086 2 8.46957 2 9V13C2 15.828 2 17.243 2.879 18.121C3.757 19 5.172 19 8 19H10C12.829 19 14.243 19 15.121 18.121C16.001 17.243 16.001 15.828 16.001 13V9C16.001 8.46957 16.2117 7.96086 16.5868 7.58579C16.9619 7.21071 17.4706 7 18.001 7L18 4ZM7.5 9C7.5 8.73478 7.39464 8.48043 7.20711 8.29289C7.01957 8.10536 6.76522 8 6.5 8C6.23478 8 5.98043 8.10536 5.79289 8.29289C5.60536 8.48043 5.5 8.73478 5.5 9V14C5.5 14.2652 5.60536 14.5196 5.79289 14.7071C5.98043 14.8946 6.23478 15 6.5 15C6.76522 15 7.01957 14.8946 7.20711 14.7071C7.39464 14.5196 7.5 14.2652 7.5 14V9ZM12.5 9C12.5 8.73478 12.3946 8.48043 12.2071 8.29289C12.0196 8.10536 11.7652 8 11.5 8C11.2348 8 10.9804 8.10536 10.7929 8.29289C10.6054 8.48043 10.5 8.73478 10.5 9V14C10.5 14.2652 10.6054 14.5196 10.7929 14.7071C10.9804 14.8946 11.2348 15 11.5 15C11.7652 15 12.0196 14.8946 12.2071 14.7071C12.3946 14.5196 12.5 14.2652 12.5 14V9Z"
                  />
                  <path
                    d="M7.06836 1.36999C7.18236 1.26399 7.43336 1.16999 7.78336 1.10299C8.18503 1.03156 8.5924 0.997084 9.00036 0.999986C9.44036 0.999986 9.86836 1.03599 10.2174 1.10299C10.5674 1.16999 10.8174 1.26399 10.9324 1.37099"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <ng-container
            *ngIf="editRequestModalService.selectedCollectionIndex === cIndex"
          >
            <app-request-section-list-item
              *ngFor="
                let section of $any(sectionCollection.get('sections'))
                  ?.controls;
                let sectionIndex = index
              "
              [sectionCollection]="$any(sectionCollection)"
              [sectionCollectionIndex]="cIndex"
              [sectionIndex]="sectionIndex"
            ></app-request-section-list-item>
            <button
              transloco="+ Add Section"
              class="w-full text-sm tw-btn-secondary-outline"
              (click)="
                editRequestModalService.addNewSection($any(sectionCollection))
              "
            >
              + Add Section
            </button>
          </ng-container>
        </div>
        <button
          transloco="+ Add Collection"
          class="tw-btn-secondary"
          (click)="editRequestModalService.addNewSectionCollection()"
        >
          + Add Collection
        </button>
      </div>
      <div class="lg:col-span-8">
        <app-selected-section-view></app-selected-section-view>
      </div>
    </div>
  </section>
</div>
<app-info-upload-modal></app-info-upload-modal>
<app-section-collection-edit-modal></app-section-collection-edit-modal>
