<!--New and Edit Client-->
<button
  data-modal-target="clientModal"
  data-modal-toggle="clientModal"
  class="hidden"
  #toggleClientModalBtn
></button>
<button
  data-modal-target="clientModal"
  data-modal-show="clientModal"
  class="hidden"
  #showClientModalBtn
></button>
<button
  data-modal-target="clientModal"
  data-modal-hide="clientModal"
  class="hidden"
  #hideClientModalBtn
></button>

<button
  data-modal-target="contactModal"
  data-modal-show="contactModal"
  class="hidden"
  #showContactModalBtn
></button>
<button
  data-modal-target="contactModal"
  data-modal-hide="contactModal"
  class="hidden"
  #hideContactModalBtn
></button>

<button
  data-modal-target="confirmCloseModal"
  data-modal-toggle="confirmCloseModal"
  data-close-modal-type="contactModal"
  class="hidden"
  #toggleConfirmCloseModalBtn
></button>
<button
  data-modal-target="confirmDeleteModal"
  data-modal-toggle="confirmDeleteModal"
  class="hidden"
  #toggleConfirmDeleteModalBtn
></button>

<div
  #clientModal
  id="clientModal"
  tabindex="-1"
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)]"
>
  <ng-container *transloco="let t;">
    <div
      class="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black/70 z-50"
    >
      <div class="relative z-50 p-3 2xl:p-4 w-full max-w-5xl">
        <!-- Modal content -->
        <div class="relative bg-white rounded-3xl shadow overflow-hidden">
          <div class="h-2 bg-orange1"></div>
          <!-- Modal header -->
          <div
            class="flex items-center justify-between p-3 2xl:p-4 md:p-5 rounded-t"
          >
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              <span
                *ngIf="editClientModalService.currentClientId"
                transloco="Edit"
                >Edit
              </span>
              <span
                *ngIf="!editClientModalService.currentClientId"
                transloco="New"
                >New
              </span>
              &nbsp;
              <span *ngIf="isProducer" transloco="Producer"
                >Producer</span
              >
              <span *ngIf="!isProducer" transloco="Client"
                >Client</span
              >
            </h3>
            <button
              type="button"
              (click)="initClientModalClose()"
              [disabled]="editClientModalService.clientForm.disabled"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only" transloco="Close modal">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div
            class="p-4 md:p-5 space-y-4 flex flex-col items-end overflow-y-auto max-h-96"
          >
            <form
              class="w-full mb-4"
              [formGroup]="editClientModalService.clientForm"
            >
              <div class="my-4" *isSuperAdmin>
                <label
                  for="customer"
                  class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                  transloco="Kunde"
                  >Kunde</label
                >
                <select
                  id="customer"
                  class="border text-xs 2xl:text-sm rounded-lg focus:ring-primaryBtn focus:border-primaryBtn block w-full p-2.5"
                  [ngClass]="
                    editClientModalService.customerIdControl.touched &&
                    editClientModalService.customerIdControl.errors
                      ? 'bg-red-50 border-red-500 text-red-900'
                      : 'bg-gray-50 border-gray-300  text-gray-900'
                  "
                  [formControl]="editClientModalService.customerIdControl"
                >
                  <option
                    [value]="customer.id"
                    *ngFor="let customer of editClientModalService.customerList"
                  >
                    {{ customer.label }}
                  </option>
                </select>
              </div>
              <label
                for="company_name"
                class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                transloco="Company Name"
                >Company Name</label
              >
              <input
                type="text"
                id="company_name"
                [ngClass]="
                  editClientModalService.companyNameControl.touched &&
                  editClientModalService.companyNameControl.errors
                    ? 'bg-red-50 border-red-500 text-red-900'
                    : 'bg-gray-50 border-gray-300  text-gray-900'
                "
                class="bg-gray-50 border border-gray-300 text-gray-900 text-xs 2xl:text-sm rounded-lg focus:ring-primaryBtn focus:border-primaryBtn block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                [placeholder]="
                t('Enter company name')"
                formControlName="company_name"
              />
              <div
                class="mt-2 text-xs 2xl:text-sm text-red-600 dark:text-red-500"
                *ngIf="
                  editClientModalService.companyNameControl.touched &&
                  editClientModalService.companyNameControl.errors
                "
              >
                <span
                  *ngIf="
                    editClientModalService.companyNameControl.hasError(
                      'required'
                    )
                  "
                  transloco="Required"
                  >Required</span
                >
                <span
                  *ngIf="
                    editClientModalService.companyNameControl.hasError(
                      'maxlength'
                    )
                  "
                  transloco="Max 255 characters allowed"
                  >Max 255 characters allowed</span
                >
              </div>
            </form>
            <div
              class="flex items-center p-3 2xl:p-4 mb-4 text-xs 2xl:text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 w-full"
              role="alert"
              *ngIf="
                editClientModalService.showContactRequiredErr &&
                !editClientModalService.contacts.length
              "
            >
              <svg
                class="flex-shrink-0 inline w-4 h-4 me-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
                />
              </svg>
              <span class="sr-only" transloco="Info">Info</span>
              <div transloco="At least one contact is required!">
                At least one contact is required!
              </div>
            </div>
            <div class="mt-8 w-full">
              <table
                *ngIf="editClientModalService.contacts.length"
                class="w-full border border-slate-200 text-xs 2xl:text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                <thead
                  class="text-xs 2xl:text-sm text-gray-700 normal-case bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                >
                  <tr>
                    <th
                      scope="col"
                      class="border border-slate-200 px-6 py-3 text-center"
                      transloco="Salutation"
                    >
                      Salutation
                    </th>
                    <th
                      scope="col"
                      class="border border-slate-200 px-2 py-3 w-1/5 text-center"
                      transloco="Firstname"
                    >
                      Firstname
                    </th>
                    <th
                      scope="col"
                      class="border border-slate-200 px-2 py-3 w-1/5 text-center"
                      transloco="Lastname"
                    >
                      Lastname
                    </th>
                    <th
                      scope="col"
                      class="border border-slate-200 px-2 py-3 w-1/5 text-center"
                      transloco="E-Mail"
                    >
                      E-Mail
                    </th>
                    <th
                      scope="col"
                      class="border border-slate-200 px-2 py-3 w-1/5 text-center"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let contact of editClientModalService.contacts;
                      let idx = index
                    "
                    class="bg-white text-xs 2xl:text-sm border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    data-expanded="false"
                  >
                    <td
                      class="border border-slate-200 px-4 py-2 2xl:px-6 2xl:py-4 text-left"
                    >
                      {{ contact.salutation }}
                    </td>
                    <td
                      class="border border-slate-200 px-4 py-2 2xl:px-6 2xl:py-4 text-left"
                    >
                      {{ contact.first_name }}
                    </td>
                    <td
                      class="border border-slate-200 px-4 py-2 2xl:px-6 2xl:py-4 text-left"
                    >
                      {{ contact.last_name }}
                    </td>
                    <td
                      class="border border-slate-200 px-4 py-2 2xl:px-6 2xl:py-4 text-left"
                    >
                      {{ contact.email }}
                    </td>
                    <td
                      class="border border-slate-200 px-4 py-2 2xl:px-6 2xl:py-4 text-left {{
                        !editClientModalService.currentClientId
                      }}"
                    >
                      <div class="flex">
                        <button
                          (click)="editContact(contact.id || idx)"
                          class="font-medium text-blue-600 dark:text -blue-500 hover:underline"
                          transloco="Edit"
                        >
                          Edit
                        </button>
                        <div class="text-primary mx-2">|</div>
                        <button
                          (click)="initDeleteContact(contact.id || idx)"
                          class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          transloco="Delete"
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex items-end">
              <button
                type="button"
                (click)="addContact()"
                class="border-2 font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center"
                transloco="Add Contact"
              >
                Add Contact
              </button>
            </div>
          </div>
          <!-- Modal footer -->
          <div
            class="flex justify-between items-center mt-10 p-3 2xl:p-4 md:p-5 rounded-b"
          >
            <button
              type="button"
              (click)="initClientModalClose()"
              [disabled]="editClientModalService.clientForm.disabled"
              class="border-2 font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center w-36"
              transloco="Cancel"
            >
              Cancel
            </button>
            <button
              *ngIf="editClientModalService.currentClientId"
              type="button"
              [disabled]="editClientModalService.clientForm.disabled"
              class="border-2 font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center w-36"
              (click)="initDeleteClient()"
              transloco="Delete"
            >
              Delete
            </button>
            <button
              type="button"
              (click)="editClientModalService.handleClientFormSubmit()"
              [disabled]="editClientModalService.clientForm.disabled"
              class="border-2 font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center w-36"
              transloco="Save"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!--Add and Edit Client-->
<div
  #contactModal
  id="contactModal"
  tabindex="-1"
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
>
  <ng-container *transloco="let t;">
    <div
      class="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black/70 z-50"
    >
      <div class="relative z-50 p-3 2xl:p-4 w-full max-w-5xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-3xl shadow overflow-hidden">
          <div class="h-2 bg-orange1"></div>
          <!-- Modal header -->
          <div
            class="flex items-center justify-between p-3 2xl:p-4 md:p-5 rounded-t"
          >
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              <span
                *ngIf="editClientModalService.currentContactId !== undefined"
                transloco="Edit Contact"
                >Edit Contact</span
              >
              <span
                *ngIf="editClientModalService.currentContactId === undefined"
                transloco="New Contact"
                >New Contact</span
              >
            </h3>
            <button
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              (click)="initContactModalClose()"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only" transloco="Close modal">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <form
            class="p-4 md:p-5 space-y-4 flex flex-col items-end"
            [formGroup]="editClientModalService.contactForm"
          >
            <div class="w-full">
              <div class="mt-4">
                <label
                  for="salutation"
                  class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                  transloco="Salutation"
                  >Salutation</label
                >
                <select
                  id="salutation"
                  class="border text-xs 2xl:text-sm rounded-lg focus:ring-primaryBtn focus:border-primaryBtn block w-full p-2.5"
                  [ngClass]="
                    editClientModalService.salutationControl.touched &&
                    editClientModalService.salutationControl.errors
                      ? 'bg-red-50 border-red-500 text-red-900'
                      : 'bg-gray-50 border-gray-300  text-gray-900'
                  "
                  formControlName="salutation"
                >
                  <option value="Herr" transloco="Mr">Mr</option>
                  <option value="Frau" transloco="Ms">Ms</option>
                  <option value="Divers" transloco="Other">Other</option>
                </select>
                <div
                  class="mt-2 text-xs 2xl:text-sm text-red-600 dark:text-red-500"
                  *ngIf="
                    editClientModalService.salutationControl.touched &&
                    editClientModalService.salutationControl.errors
                  "
                  transloco="Required"
                >
                  Required
                </div>
              </div>
              <div class="flex w-full space-x-3">
                <div class="mt-4 w-full">
                  <label
                    for="first_name"
                    class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                    transloco="Firstname"
                    >Firstname</label
                  >
                  <input
                    type="text"
                    id="first_name"
                    class="border text-xs 2xl:text-sm rounded-lg focus:ring-primaryBtn focus:border-primaryBtn block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    [ngClass]="
                      editClientModalService.firstNameControl.touched &&
                      editClientModalService.firstNameControl.errors
                        ? 'bg-red-50 border-red-500 text-red-900'
                        : 'bg-gray-50 border-gray-300  text-gray-900'
                    "
                    [placeholder]="t('Enter firstname')"
                    formControlName="first_name"
                  />
                  <div
                    class="mt-2 text-xs 2xl:text-sm text-red-600 dark:text-red-500"
                    *ngIf="
                      editClientModalService.firstNameControl.touched &&
                      editClientModalService.firstNameControl.errors
                    "
                  >
                    <span
                      *ngIf="
                        editClientModalService.firstNameControl.hasError(
                          'required'
                        )
                      "
                      transloco="Required"
                      >Required</span
                    >
                    <span
                      *ngIf="
                        editClientModalService.firstNameControl.hasError(
                          'maxlength'
                        )
                      "
                      transloco="Max 255 characters allowed"
                      >Max 255 characters allowed</span
                    >
                  </div>
                </div>
                <div class="mt-4 w-full">
                  <label
                    for="last_name"
                    class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                    transloco="Lastname"
                    >Lastname</label
                  >
                  <input
                    type="text"
                    id="last_name"
                    class="border text-xs 2xl:text-sm rounded-lg focus:ring-primaryBtn focus:border-primaryBtn block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    [ngClass]="
                      editClientModalService.lastNameControl.touched &&
                      editClientModalService.lastNameControl.errors
                        ? 'bg-red-50 border-red-500 text-red-900'
                        : 'bg-gray-50 border-gray-300  text-gray-900'
                    "
                    [placeholder]="t('Enter lastname')"
                    formControlName="last_name"
                  />
                  <div
                    class="mt-2 text-xs 2xl:text-sm text-red-600 dark:text-red-500"
                    *ngIf="
                      editClientModalService.lastNameControl.touched &&
                      editClientModalService.lastNameControl.errors
                    "
                  >
                    <span
                      *ngIf="
                        editClientModalService.lastNameControl.hasError(
                          'required'
                        )
                      "
                      transloco="Required"
                      >Required</span
                    >
                    <span
                      *ngIf="
                        editClientModalService.lastNameControl.hasError(
                          'maxlength'
                        )
                      "
                      transloco="Max 255 characters allowed"
                      >Max 255 characters allowed</span
                    >
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <label
                  for="email"
                  class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                  transloco="E-Mail"
                  >E-Mail</label
                >
                <input
                  type="text"
                  id="email"
                  class="border text-xs 2xl:text-sm rounded-lg focus:ring-primaryBtn focus:border-primaryBtn block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  [ngClass]="
                    editClientModalService.emailControl.touched &&
                    editClientModalService.emailControl.errors
                      ? 'bg-red-50 border-red-500 text-red-900'
                      : 'bg-gray-50 border-gray-300  text-gray-900'
                  "
                  [placeholder]="t('Enter email')"
                  formControlName="email"
                />
                <div
                  class="mt-2 text-xs 2xl:text-sm text-red-600 dark:text-red-500"
                  *ngIf="
                    editClientModalService.emailControl.touched &&
                    editClientModalService.emailControl.errors
                  "
                >
                  <span
                    *ngIf="
                      editClientModalService.emailControl.hasError('required')
                    "
                    transloco="Required"
                    >Required</span
                  >
                  <span
                    *ngIf="
                      editClientModalService.emailControl.hasError('email')
                    "
                    transloco="Invalid email"
                    >Invalid email</span
                  >
                  <span
                    *ngIf="
                      editClientModalService.emailControl.hasError('maxlength')
                    "
                    transloco="Max 255 characters allowed"
                    >Max 255 characters allowed</span
                  >
                </div>
              </div>
            </div>
          </form>
          <!-- Modal footer -->
          <div
            class="flex justify-between items-center mt-10 p-3 2xl:p-4 md:p-5 rounded-b"
          >
            <button
              (click)="initContactModalClose()"
              type="button"
              class="border-2 font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center w-36"
              transloco="Cancel"
            >
              Cancel
            </button>
            <button
              *ngIf="editClientModalService.currentContactId !== undefined"
              type="button"
              class="border-2 font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center w-36"
              (click)="
                initDeleteContact(editClientModalService.currentContactId)
              "
              transloco="Delete"
            >
              Delete
            </button>
            <button
              type="button"
              class="border-2 font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center w-36"
              (click)="editClientModalService.handleContactFormSubmit()"
              transloco="Save"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div
  id="confirmCloseModal"
  #confirmCloseModal
  tabindex="-1"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
>
  <div class="relative p-3 2xl:p-4 w-full max-w-md max-h-full">
    <div class="relative bg-white rounded-lg shadow-xl dark:bg-gray-700">
      <button
        type="button"
        class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        data-modal-hide="confirmCloseModal"
      >
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span class="sr-only" transloco="Close modal">Close modal</span>
      </button>
      <div class="p-4 md:p-5 text-center">
        <svg
          class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
        <h3
          class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
          transloco="Are you sure you want to cancel?"
        >
          Are you sure you want to cancel?
        </h3>
        <button
          data-modal-hide="confirmCloseModal"
          type="button"
          (click)="handleConfirmCloseClick()"
          class="text-white bg-primaryBtn hover:bg-primaryBtnHover focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-xs 2xl:text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
          transloco="Yes"
        >
          Yes
        </button>
        <button
          data-modal-hide="confirmCloseModal"
          type="button"
          class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          transloco="No"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>

<div
  id="confirmDeleteModal"
  #confirmDeleteModal
  data-delete-type="client"
  tabindex="-1"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
>
  <div class="relative p-3 2xl:p-4 w-full max-w-md max-h-full">
    <div class="relative bg-white rounded-lg shadow-xl dark:bg-gray-700">
      <button
        type="button"
        class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        data-modal-hide="confirmDeleteModal"
      >
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span class="sr-only" transloco="Close modal">Close modal</span>
      </button>
      <div class="p-4 md:p-5 text-center">
        <svg
          class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
        <h3
          class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
          *ngIf="
            confirmDeleteModal.getAttribute('data-delete-type') === 'client'
          "
          transloco="Are you sure you want to delete this client and all of their data?"
        >
          Are you sure you want to delete this client and all of their data?
        </h3>
        <h3
          class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
          *ngIf="
            confirmDeleteModal.getAttribute('data-delete-type') === 'contact'
          "
          transloco="Are you sure you want to remove this contact?"
        >
          Are you sure you want to remove this contact?
        </h3>
        <button
          data-modal-hide="confirmDeleteModal"
          type="button"
          (click)="
            confirmDeleteModal.getAttribute('data-delete-type') === 'client'
              ? editClientModalService.deleteClient()
              : editClientModalService.deleteContact()
          "
          class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-xs 2xl:text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
          transloco="Yes"
        >
          Yes
        </button>
        <button
          data-modal-hide="confirmDeleteModal"
          type="button"
          class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          transloco="No"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
